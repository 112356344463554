




























































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Level extends Vue {
	pagerHeader: any = {
		title: '等级日志列表',
		desc: '等级日志列表',
		breadcrumb: ['等级日志列表', '代理管理'],
    }
    loading: boolean = false;
  filter: any = {
    memberId: 0,
    reasons: null,
    startDate: null,
    endDate: null,
    merchantId: "",
    keywords: "",
    page: 1,
    size: 10,
    sortField: null,
    sortDirection: null
  };
  reasonValues: any = [];
  dateRange: any = [];
  tableData: any = {
    items: [

    ],
    totalCount: 0
  };
  created() {
      this.readerPage();
  }
//     @Watch("dateRange")
//   dateRangeChnage(value: Date[]) {
//     if (value != null && value.length == 2) {
//       this.filter.startDate = value[0].Format("yyyy-MM-dd hh:mm:ss");
//       this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss");
//       this.readerPage();
//     } else {
//       this.filter.startDate = "";
//       this.filter.endDate = "";
//       this.readerPage();
//     }
//   }
  // 改变多少条一页
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.readerPage();
  }
  // 点击第几页
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.readerPage();
  }
  async readerPage() {
    // this.loading = true;
    // try {
    //   let res = await this.$ajax.post(
    //     "",
    //     {
    //       ...this.filter
    //     }
    //   );

    //   if (res.data.success) {
    //     this.tableData = res.data.result;
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
    // this.loading = false;
  }
    //  搜索
  search() {
    this.filter.page = 1;
    this.readerPage();
  }
}
